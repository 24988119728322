import { useDispatch, useSelector } from "react-redux";
import { selectMapInfo, setMapCoordinates, setMapAddress, addMapParcelle, setMapParcelles, setBrouillon, setInvitationToken, setMapCadastres, setMapSelectedCadastres } from "../store/mapInfoSlice";
import { 
    selectCarto, 
    setSelectedCard as setSelectedCardStore,
    setMutations as setMutationsStore, 
    setSelectedParcelle as setSelectedparcelleStore,
    setCanViewParcelleData as setCanViewParcelleDataStore,
    setDocumentsUrbanisme as setDocumentsUrbanismeStore, 
    setZonesUrbaine as setZonesUrbaineStore, 
    setInformations as setInformationsStore, 
    setServitudes as setServitudesStore, 
    setShowedFeatures as setShowedFeaturesStore,
    setNbDocumentsUrbanisme as setNbDocumentsUrbanismeStore,
    setNbZonesUrbaine as setNbZonesUrbaineStore,
    setNbInformations as setNbInformationsStore,
    setNbServitudes as setNbServitudesStore,
    setLoadingDocumentsUrbanisme as setLoadingDocumentsUrbanismeStore,
    setLoadingZonesUrbaine as setLoadingZonesUrbaineStore,
    setLoadingInformations as setLoadingInformationsStore,
    setLoadingServitudes as setLoadingServitudesStore
} from "../store/cartoSlice";
import { Feature } from "geojson";

export const useCarto = () => {
    const couleurs = [
        '#FFA07A', '#20B2AA', '#87CEFA', '#CD5C5C', '#FFD700', '#FF6347', '#00FF7F', '#FF7F50', '#FF69B4', '#778899',
        '#FF6347', '#FF4500', '#FFD700', '#FFA500', '#FF8C00', '#FF7F50', '#FF6347', '#FF4500', '#FFD700', '#FFA500',
        '#FFC0CB', '#FFB6C1', '#FF69B4', '#FF1493', '#DB7093', '#C71585', '#FFA07A', '#FA8072', '#E9967A', '#F08080',
        '#FF8C00', '#FF7F50', '#FF6347', '#FF4500', '#FFD700', '#FFA500', '#FF8C00', '#00FA9A', '#FF6347', '#FF4500'
    ];

    const dispatch = useDispatch();
    const carto = useSelector(selectCarto);

    const setSelectedCard = (selectedCard) => {
        dispatch(setSelectedCardStore(selectedCard));
    }

    const setMutations = (mutations) => {
        dispatch(setMutationsStore(mutations));
    }

    const setSelectedParcelle = (parcelle) => {
        dispatch(setSelectedparcelleStore(parcelle));
    }

    const setCanViewParcelleData = (canViewParcelleData) => {
        dispatch(setCanViewParcelleDataStore(canViewParcelleData));
    }

    const setDocumentsUrbanisme = (documentsUrbanisme) => {
        dispatch(setDocumentsUrbanismeStore(documentsUrbanisme));
    }

    const setZonesUrbaine = (zonesUrbaine) => {
        dispatch(setZonesUrbaineStore(zonesUrbaine));
    }

    const setInformations = (informations) => {
        dispatch(setInformationsStore(informations));
    }

    const setServitudes = (servitudes) => {
        dispatch(setServitudesStore(servitudes));
    }

    const setShowedFeatures = (showedFeatures) => {
        dispatch(setShowedFeaturesStore(showedFeatures));
    }

    const setNbDocumentsUrbanisme = (nbDocumentsUrbanisme) => {
        dispatch(setNbDocumentsUrbanismeStore(nbDocumentsUrbanisme));
    }

    const setNbZonesUrbaine = (nbZonesUrbaine) => {
        dispatch(setNbZonesUrbaineStore(nbZonesUrbaine));
    }

    const setNbInformations = (nbInformations) => {
        dispatch(setNbInformationsStore(nbInformations));
    }

    const setNbServitudes = (nbServitudes) => {
        dispatch(setNbServitudesStore(nbServitudes));
    }

    const setLoadingDocumentsUrbanisme = (loadingDocumentsUrbanisme) => {
        dispatch(setLoadingDocumentsUrbanismeStore(loadingDocumentsUrbanisme));
    }

    const setLoadingZonesUrbaine = (loadingZonesUrbaine) => {
        dispatch(setLoadingZonesUrbaineStore(loadingZonesUrbaine));
    }

    const setLoadingInformations = (loadingInformations) => {
        dispatch(setLoadingInformationsStore(loadingInformations));
    }

    const setLoadingServitudes = (loadingServitudes) => {
        dispatch(setLoadingServitudesStore(loadingServitudes));
    }

    const getLayerColor = (feature) => {
        let index = 0;
        let startIndex = 0;
        //Get index of feature in zoneUrbaine
        if(carto.zonesUrbaine) {
            let zoneUrbaineIndex = carto.zonesUrbaine.features.findIndex((zone) => {
                return zone.properties.id == feature.properties.id;
            });
            if(zoneUrbaineIndex != -1) index = zoneUrbaineIndex;
            startIndex = carto.zonesUrbaine.features.length;
        }
        //Get index of feature in infoUrbanisme
        if(carto.informations) {
            let infoUrbanismeIndex = carto.informations.features.findIndex((info) => {
                return info.properties.id == feature.properties.id;
            });
            if(infoUrbanismeIndex != -1) index = infoUrbanismeIndex+startIndex;
            startIndex = startIndex + carto.informations.features.length;
        }
        //Get index of feature in servitudes
        if(carto.servitudes) {
            let servitudeIndex = carto.servitudes.features.findIndex((servitude) => {
                return servitude.properties.id == feature.properties.id;
            });
            if(servitudeIndex != -1) index = servitudeIndex+startIndex;
            startIndex = startIndex + carto.servitudes.features.length;
        }

        let color = couleurs[index];

        return color;
    }

    const handleShowHideFeature = (feature, forceShow = false) => {
        if(carto.showedFeatures == null) setShowedFeatures({type: 'FeatureCollection', features: [feature]});
        else {
            let newShowedFeatures = {...carto.showedFeatures};
            if(newShowedFeatures.features.includes(feature)) {
                if(!forceShow) {
                    newShowedFeatures.features = newShowedFeatures.features.filter((f) => {
                        return f.properties.id != feature.properties.id;
                    });
                }
            } else {
                newShowedFeatures.features = [...newShowedFeatures.features, feature];
            }
            setShowedFeatures(newShowedFeatures);
        }
    }

    const handleShowFeatures = (features: Feature[]) => {
        if(carto.showedFeatures == null) setShowedFeatures({type: 'FeatureCollection', features: features});
        else {
            let newShowedFeatures = {...carto.showedFeatures};
            features.forEach((feature) => {
                if(!newShowedFeatures.features.includes(feature)) {
                    newShowedFeatures.features = [...newShowedFeatures.features, feature];
                }
            });
            setShowedFeatures(newShowedFeatures);
        }
    }

    const handleHideFeatures = (features: Feature[]) => {
        if(carto.showedFeatures != null) {
            let newShowedFeatures = [];
            carto.showedFeatures.features.forEach((feature) => {
                let keepFeature = true;
                features.forEach((f) => {
                    if(f.properties.id == feature.properties.id) keepFeature = false;
                });
                if(keepFeature) newShowedFeatures.push(feature);
            });
            if(newShowedFeatures.length == 0) setShowedFeatures(null);
            else setShowedFeatures({type: 'FeatureCollection', features: newShowedFeatures});
        }
    }

    return {
        carto,
        setSelectedCard,
        setMutations,
        setSelectedParcelle,
        setCanViewParcelleData,
        setDocumentsUrbanisme,
        setZonesUrbaine,
        setInformations,
        setServitudes,
        setShowedFeatures,
        setNbDocumentsUrbanisme,
        setNbZonesUrbaine,
        setNbInformations,
        setNbServitudes,
        getLayerColor,
        handleShowHideFeature,
        handleShowFeatures,
        handleHideFeatures,
        setLoadingDocumentsUrbanisme,
        setLoadingZonesUrbaine,
        setLoadingInformations,
        setLoadingServitudes
    }
}
