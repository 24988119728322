import { AxiosError, AxiosResponse } from "axios";
import { api } from "../helpers/api";
import { Brouillon } from "../models/Brouillon";
import { User } from "../models/User";
import { get } from "http";
import { useDispatch, useSelector } from "react-redux";
import { selectProfile, setProfile } from "../store/profileSlice";

export const useUser = () => {
    const dispatch = useDispatch();
    const profile = useSelector(selectProfile);
    
    const createUserFromInvitation = (token: string, password: string) => {
        return new Promise<User>((resolve, reject) => {
            api.post('/invite/' + token, {password})
                .then((response: AxiosResponse<User>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    const getProfile = () => {
        return new Promise<User>((resolve, reject) => {
            api.get('/me')
                .then((response: AxiosResponse<User>) => {
                    resolve(response?.data);
                    dispatch(setProfile(response?.data));
                })
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    return {
        profile,
        createUserFromInvitation,
        getProfile
    }
}
