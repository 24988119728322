import { useParams } from "react-router-dom";
import Map from "../../sharedComponents/Map";
import CardAddress from "../../sharedComponents/CardAddress";
import { useDossierBrouillon } from "../../hooks/useDossierBrouillon";
import { useEffect, useState } from "react";
import { Brouillon } from "../../models/Brouillon";
import { useMapInfo } from "../../hooks/useMapInfo";
import { useMapUtil } from "../../hooks/useMapUtil";
import ResponseStep1 from "./components/ResponseStep1";
import ResponseStep2 from "./components/ResponseStep2";
import Swal from "sweetalert2";
import ResponseStep4 from "./components/ResponseStep4";
import MapCadastreLayer from "../../sharedComponents/MapCadastreLayer";


function DbResponse() {
    /**
     * Hooks
     */
    const routeParams = useParams();
    const { dbToken } = routeParams;
    const { getDossierBrouillonByToken, cancelDossierBrouillonByToken } = useDossierBrouillon();
    const { mapInfo, addStoreParcelle, removeStoreParcelle, setStoreAddress, setStoreBrouillon } = useMapInfo();
    const { createLoader, deleteLoader } = useMapUtil();

    /**
     * States
     */
    
    
    /**
     * useEffect
     */
    useEffect(() => {
        if (dbToken) {
            createLoader('loadingDossierBrouillon', 'Chargement du dossier brouillon en cours...');
            getDossierBrouillonByToken(dbToken)
                .then((data) => {
                    //Si le dossier brouillon n'est pas au statut "A compléter", on lève une erreur
                    if(data.statut.code !== "STATUTBROUILLON_ACOMPLETER")
                        Swal.fire({
                            title: 'Erreur',
                            text: "Ce bien n'est plus modifiable.",
                            icon: 'error'
                        });
                    else setStoreBrouillon(data);
                    deleteLoader('loadingDossierBrouillon');
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Erreur',
                        text: error.detail,
                        icon: 'error'
                    });
                    deleteLoader('loadingDossierBrouillon');
                });
        }
    }, [dbToken]);

    useEffect(() => {
        if (mapInfo.brouillon) {
            //On met à jour l'adresse de la carte sur le dossier brouillon
            setStoreAddress({
                id: null,
                label: (mapInfo.brouillon.adressePostale?mapInfo.brouillon.adressePostale + ' ':'') + mapInfo.brouillon.codePostal + ' ' + mapInfo.brouillon.commune,
                postcode: mapInfo.brouillon.codePostal,
                housenumber: null,
                street: null,
                city: mapInfo.brouillon.commune,
                citycode: null,
                score: null,
                coordinates: {
                    latitude: null,
                    longitude: null
                }
            });
        }
    }, [mapInfo.brouillon]);

    /**
     * Handlers
     */
    const handleClickOnCadastre = (feature) => {
        //On regarde si la parcelle est déjà présente dans le store
        let isAlreadyPresent = false;
        mapInfo.parcelles.forEach((parcelle) => {
            if(parcelle.code == feature.properties.code) isAlreadyPresent = true;
        });

        //Si elle n'est pas présente, on l'ajoute
        if(!isAlreadyPresent) {
            let coordinates = null;
            if(feature.geometry.type == 'GeometryCollection') coordinates = feature.geometry.geometries[0].coordinates[0];
            else coordinates = feature.geometry.coordinates[0];

            addStoreParcelle({
                    code: feature.properties.code,
                    commune: feature.properties.commune,
                    surface: feature.properties.contenance,
                    prefixe: feature.properties.prefixe,
                    section: feature.properties.section,
                    numero: feature.properties.numero,
                    coordinates: coordinates,
                    feature: feature
                });
        }
        else {
            //Sinon on la supprime
            removeStoreParcelle(feature.properties.code);
        }
    }

    /**
     * Render
     */
    if (!dbToken) return null;

    return (
        <div>
            <Map>
                <MapCadastreLayer onCadastreClick={handleClickOnCadastre} />
            </Map>
            <div className="layout-topleft">
                <CardAddress />
            </div>
            <div style={{ position: 'absolute', bottom: '10px', left: '0px', width: '100%', zIndex: 10000 }} className="d-flex justify-content-center">
                {mapInfo.brouillon && mapInfo.brouillon.etape === 1 && (
                    <ResponseStep1 />   
                )}
                <ResponseStep2 />
                <ResponseStep4 />
            </div>
        </div>
    );
}

export default DbResponse;
