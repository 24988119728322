//React
import { useEffect, useState } from "react";

//Hooks
import { useApi } from "../hooks/useApi";
import { useMapInfo } from "../hooks/useMapInfo";
import { useMapUtil } from "../hooks/useMapUtil";

//Leaflet & geo
import { GeoJSON, Marker, Pane, useMapEvents } from 'react-leaflet';
import * as d3 from "d3";
import { LatLng, LatLngBounds } from "leaflet";
import { geoExpandBounds, getLargestInscribedRectangle } from "../helpers/GeoHelper";
import { api } from "../helpers/api";
import * as turf from "@turf/turf";
import { map } from "zod";

//params
interface MapCadastreLayerParams {
    onCadastreClick?: (cadastre) => void;
}

function MapCadastreLayer({onCadastreClick}: MapCadastreLayerParams) {
    /**
     * Hooks
     */
    const { mapInfo, setStoreCadastres, setStoreSelectedCadastres } = useMapInfo();
    const { createLoader, deleteLoader, isLoader } = useMapUtil();
    const { getCadastresGeoJSON } = useApi();
    const mapEvent = useMapEvents({
        //Detect zoom change
        zoomend: () => {
            setActualZoom(mapEvent.getZoom());
        },
        //Detect move change
        moveend: () => {
            setCenter(mapEvent.getCenter());
            setBounds(mapEvent.getBounds());
        }
    });

    /**
     * States
     */
    const [layerKey, setLayerKey] = useState(0); //Pour forcer le rechargement du layer cadastre (voir useEffect)
    const [actualZoom, setActualZoom] = useState(0);
    const [center, setCenter] = useState<LatLng>(null);
    const [bounds, setBounds] = useState<LatLngBounds>(null);
    const [cadastreAbortController, setCadastreAbortController] = useState(null);

    /**
     * useEffects
     */
    useEffect(() => {
        if(mapInfo.address?.citycode) {
            
        }
    }, [mapInfo.address]);

    useEffect(() => {
        setLayerKey(layerKey + 1);
    }, [mapInfo.cadastres, mapInfo.selectedCadastres]);

    useEffect(() => {
        if(actualZoom >= 17 && !isLoader('regions')) {
            createLoader('cadastre', 'Chargement du cadastre...');
            //TODO: vérifier que le nouveau bounds n'est pas inclu dans le bounds des cadastre déjà chargés
            //Création des coordonnées englobant tous les cadastres déjà chargés
            //Solution: on stocke tous les bounds chargés, (on stocke dans le store les cadastre liés à ces bounds?) puis on regarde si le bound qu'on veut charger est dans les bounds déjà chargés fusionnés
            //Mais comment on gère la libération mémoire des cadastres trop éloignés?
            
            let newBounds = geoExpandBounds(bounds, 300);

            //On arrête dans axios la requête précédente
            if(cadastreAbortController) cadastreAbortController.abort();
            const controller = new AbortController();
            setCadastreAbortController(controller);            

            getCadastresGeoJSON(null, newBounds.toBBoxString(), controller.signal)
                .then((data) => {
                    //On fusionne les features pour éviter les doublons
                    let features = mapInfo.cadastres==null?[]:mapInfo.cadastres.length==0?[]:mapInfo.cadastres.features;
                    data.features.forEach((feature) => {
                        let isAlreadyPresent = false;
                        for(let i = 0; i < features.length; i++) {
                            if(features[i].properties.code == feature.properties.code) isAlreadyPresent = true;
                        }
                        if(!isAlreadyPresent) {
                            features = [...features, feature];
                        }
                    });
                    setStoreCadastres({
                        type: "FeatureCollection",
                        features: features
                    });
                    //TODO: supprimer les cadastres trop éloignés
                    deleteLoader('cadastre');
                })
                .catch((error) => {
                    deleteLoader('cadastre');
                    if(error.code != "ERR_CANCELED") console.error(error); //TODO: Gérer l'erreur
                    
                });
        }
    }, [actualZoom, center, bounds]);

    useEffect(() => {
        if(mapInfo.parcelles) {
            setStoreSelectedCadastres({
                type: "FeatureCollection",
                features: mapInfo.parcelles.map((parcelle) => {
                    return parcelle.feature;
                })
            });
        }
    }, [mapInfo.parcelles]);

    if(!mapInfo.cadastres || actualZoom < 17) return (
        <>
        </>
    );

    /**
     * Handlers
     */
    const handleClickOnCadastre = (e) => {
        if(onCadastreClick) onCadastreClick(e.target.feature);
        /**/
    }

    const onEachCadastre = (feature, layer) => {
        layer.on({
            click: handleClickOnCadastre
        });
    }
    
    return (
        <>
            <Pane name="cadastre" style={{zIndex: 400}}>
                <GeoJSON
                    key={layerKey}
                    data={mapInfo.cadastres}
                    style={{color: 'white', fillOpacity: 0}}
                    onEachFeature={onEachCadastre}/>
                {mapInfo.selectedCadastres &&
                    <GeoJSON
                        key={layerKey+'selected'}
                        data={mapInfo.selectedCadastres}
                        style={{color: 'red', fillOpacity: 0}}
                        onEachFeature={onEachCadastre}/>
                }
            </Pane>
        </>
    );
}

export default MapCadastreLayer;