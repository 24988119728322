import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDossier } from "../../../hooks/useDossier";
import { Dossier } from "../../../models/Dossier";
import { GeoJSON, Pane } from 'react-leaflet';

//Bootstrap
import { Card, InputGroup, Button, Row, Col, Badge, Accordion, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload, faEye, faEyeSlash, faHouse, faLocationCrosshairs, faSquare, faSquareFull, faX } from "@fortawesome/free-solid-svg-icons";
import { faSquare as faSquareRegular } from "@fortawesome/free-regular-svg-icons";
import { useCarto } from "../../../hooks/useCarto";
import { useApi } from "../../../hooks/useApi";
import { Feature, FeatureCollection } from "geojson";
import { DomEvent, latLng, Layer } from "leaflet";
import L from "leaflet";
import { TransactionModal } from "./TransactionModal";
import { DocumentsUrbanisme } from "./DocumentsUrbanisme";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

//Params

export const InformationsCard = () => {
    /**
     * Hooks
     */
    const { carto, getLayerColor, handleShowHideFeature, handleShowFeatures, handleHideFeatures, setSelectedCard } = useCarto();
    const divContainer = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const [showAllFeatures, setShowAllFeatures] = useState(false);

    /**
     * Constants
     */
    
    /**
     * useEffect
     */
    useEffect(() => {
        if(divContainer.current) {
            DomEvent.disableScrollPropagation(divContainer.current);
        }
    }, [divContainer, carto.selectedParcelle]);

    /**
     * Handlers
     */
    const handleClose = () => {
        setSelectedCard(null);
    }

    const toggleShowHideAllFeatures = () => {
        setShowAllFeatures(!showAllFeatures);
        if(!showAllFeatures) {
            handleShowFeatures(carto.informations.features);
        } else {
            handleHideFeatures(carto.informations.features);
        }
    }

    /**
     * Functions
     */

    /**
     * Render
     */
    if(!carto.selectedParcelle) return null;

    return (
        <div>
            <Card className="card-cadastre mt-2" style={{ width: '500px', maxHeight: 'calc(100vh - 20px)' }}>
                <Card.Body>
                    <Row>
                        <Col className="d-flex mb-0 justify-content-between">
                            <span>Informations urbaines de la parcelle</span>
                            <div>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={(
                                        <Tooltip id="tooltip">
                                            {showAllFeatures?"Cacher toutes les informations":"Afficher toutes les informations"}
                                        </Tooltip>
                                    )}>
                                    <FontAwesomeIcon icon={showAllFeatures?faEyeSlash:faEye} style={{ cursor: 'pointer' }} onClick={toggleShowHideAllFeatures} />
                                </OverlayTrigger>
                                <FontAwesomeIcon icon={faX} style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleClose} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-1 h3">
                            {carto.selectedParcelle.properties.prefixe} / {carto.selectedParcelle.properties.section} / {carto.selectedParcelle.properties.numero} - {carto.selectedParcelle.properties.contenance} m²
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {!carto.informations || carto.informations?.features?.length == 0 ? (
                                <div className="text-muted">
                                    Aucune information trouvée pour cette parcelle
                                </div>
                            ) : (
                                <>
                                    {carto.informations && carto.informations.features.map((info) => info.properties.typeUrba?.label).filter((item, pos) => carto.informations.features.map((info) => info.properties.typeUrba?.label).indexOf(item) == pos).map((typeUrba) => (
                                        <div key={typeUrba} className="my-2">
                                            <strong>{typeUrba}</strong>
                                            {carto.informations.features.filter((feature) => feature.properties.typeUrba?.label == typeUrba ).map((info) => (
                                                <div key={info.properties.id} className="my-1">
                                                    <FontAwesomeIcon size="lg" icon={carto.showedFeatures?(carto.showedFeatures.features?.find((showedFeature) => showedFeature.properties.id == info.properties.id)?faSquare:(faSquareRegular as IconProp)):(faSquareRegular as IconProp)} color={getLayerColor(info)} style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleShowHideFeature(info)} /> {info.properties.labelong}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}
