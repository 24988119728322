import { LatLng, LatLngBounds } from "leaflet";
import * as turf from "@turf/turf";

export const geoExpandBounds = (bounds: LatLngBounds, distanceInMeters: number) => {
    // Approximation : 1 degré de latitude correspond à environ 111 km (varie légèrement selon la latitude)
    var latConversionFactor = 111320; // en mètres
    var lngConversionFactor = 40075017 * Math.cos(bounds.getCenter().lat * Math.PI / 180) / 360; // en mètres
    
    var latOffset = distanceInMeters / latConversionFactor;
    var lngOffset = distanceInMeters / lngConversionFactor;
    
    // Agrandir la bounding box de la distance en mètres
    var sw = bounds.getSouthWest();
    var ne = bounds.getNorthEast();
    
    var newSw = new LatLng(sw.lat - latOffset, sw.lng - lngOffset);
    var newNe = new LatLng(ne.lat + latOffset, ne.lng + lngOffset);
    
    return new LatLngBounds(newSw, newNe);
}

export const geoGetBoundsFromGeometry = (geometry: any) => {
    var minLat = Infinity;
    var maxLat = -Infinity;
    var minLng = Infinity;
    var maxLng = -Infinity;
    
    geometry.coordinates[0].forEach((coord) => {
        if(coord[0] < minLng) minLng = coord[0];
        if(coord[0] > maxLng) maxLng = coord[0];
        if(coord[1] < minLat) minLat = coord[1];
        if(coord[1] > maxLat) maxLat = coord[1];
    });
    
    return new LatLngBounds(new LatLng(minLat, minLng), new LatLng(maxLat, maxLng));
}

export const geoGetCenter = (bounds: LatLngBounds) => {
    return bounds.getCenter();
}

export const calculateRectangleFromPoint = (point, polygon) => {
    // Algorithme personnalisé pour étendre un rectangle à partir du point
    // jusqu'à ce qu'il touche les limites de la géométrie

    // Cette partie nécessite un algorithme spécifique qui recherche les
    // intersections des lignes partant du point dans les quatre directions
    // (nord, est, sud, ouest) avec les bords de la géométrie.

    // Pour simplifier ici, nous allons retourner un rectangle fictif.
    return {
        area: 1, // La superficie calculée du rectangle
        coordinates: [
        [point.geometry.coordinates[0] - 0.001, point.geometry.coordinates[1] - 0.001],
        [point.geometry.coordinates[0] + 0.001, point.geometry.coordinates[1] - 0.001],
        [point.geometry.coordinates[0] + 0.001, point.geometry.coordinates[1] + 0.001],
        [point.geometry.coordinates[0] - 0.001, point.geometry.coordinates[1] + 0.001],
        [point.geometry.coordinates[0] - 0.001, point.geometry.coordinates[1] - 0.001]
        ]
    };
}

export const getLargestInscribedRectangle = (polygon) => {
    // Étape 1 : Définir une grille de points à l'intérieur de la géométrie
    const bbox = turf.bbox(polygon);
    const grid = turf.pointGrid(bbox, 0.001, { units: 'kilometers' });
  
    let maxArea = 0;
    let bestRectangle = null;
  
    grid.features.forEach(point => {
      if (turf.booleanPointInPolygon(point, polygon)) {
        // Calculer le plus grand rectangle possible centré sur ce point
        const rectangle = calculateRectangleFromPoint(point, polygon);
  
        if (rectangle && rectangle.area > maxArea) {
          maxArea = rectangle.area;
          bestRectangle = rectangle;
        }
      }
    });
  
    return bestRectangle;
  }

export const calculateCentroid = (polygon) => {
    return turf.centroid(polygon);
}