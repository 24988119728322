import { combineReducers, configureStore } from '@reduxjs/toolkit'
import mapInfoReducer from './mapInfoSlice'
import mapUtilReducer from './mapUtilSlice'
import cartoReducer from './cartoSlice'
import profileReducer from './profileSlice'
import { profile } from 'console'

export const store = configureStore({
  reducer: combineReducers({
      mapInfo: mapInfoReducer,
      mapUtil: mapUtilReducer,
      carto: cartoReducer,
      profile: profileReducer
    }),
})

