import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDossier } from "../../../hooks/useDossier";
import { Dossier } from "../../../models/Dossier";

//Bootstrap
import { Card, InputGroup, Button, Row, Col, Badge } from "react-bootstrap";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../hooks/useUser";
import { useCarto } from "../../../hooks/useCarto";

//Params
interface DossierCardParams {
    dossier: Dossier;
}

export const DossierCard = ({ dossier }: DossierCardParams) => {
    /**
     * Hooks
     */
    const { getDossier } = useDossier();
    const { mapInfo, centerMap } = useMapInfo();
    const { getProfile } = useUser();
    const { carto, setSelectedCard } = useCarto();

    /**
     * States
     */
    const [ soldeCredits, setSoldeCredits ] = useState(0);
    
    /**
     * useEffect
     */
    useEffect(() => {
        getProfile()
            .then((user) => {
                if(user.contact) setSoldeCredits(user.contact.tier.credits);
            });
    }, [mapInfo.selectedCadastres]);

    /**
     * Handlers
     */
    const handleClose = () => {
        setSelectedCard(null);
    }

    /**
     * Render
     */
    if (!dossier) return null;

    return (
        <div>
            <Card className="card-dossier">
                <Card.Body>
                    <Row>
                        <Col className="mb-2 d-flex justify-content-between content-align-center">
                            <div>
                                <span className="mr-3">Dossier n°{dossier.id}</span>
                            </div>
                            <div style={{ marginLeft: '10px'}}>
                                <Badge bg="success">{dossier.etat.regroupement.label}</Badge>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-0 h2">
                            {dossier.commune}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-0">
                            {dossier.adresse} <FontAwesomeIcon icon={faLocationCrosshairs} style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={() => centerMap({ latitude: dossier.latitude, longitude: dossier.longitude }, 19)} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}
