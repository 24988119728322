import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDossier } from "../../../hooks/useDossier";
import { Dossier } from "../../../models/Dossier";
import { GeoJSON, Pane } from 'react-leaflet';

//Bootstrap
import { Card, InputGroup, Button, Row, Col, Badge, Accordion, Spinner } from "react-bootstrap";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload, faEye, faEyeSlash, faHouse, faLocationCrosshairs, faSquare, faSquareFull } from "@fortawesome/free-solid-svg-icons";
import { faSquare as faSquareRegular } from "@fortawesome/free-regular-svg-icons";
import { useCarto } from "../../../hooks/useCarto";
import { useApi } from "../../../hooks/useApi";
import { Feature, FeatureCollection } from "geojson";
import { DomEvent, latLng, Layer } from "leaflet";
import L from "leaflet";

//Params

export const CadastreInfosLayer = () => {
    /**
     * Hooks
     */
    const { 
        carto, 
        setCanViewParcelleData,
        setDocumentsUrbanisme, 
        setZonesUrbaine, 
        setInformations, 
        setServitudes, 
        setNbDocumentsUrbanisme,
        setNbZonesUrbaine,
        setNbInformations,
        setNbServitudes,
        setShowedFeatures,
        setLoadingDocumentsUrbanisme,
        setLoadingZonesUrbaine,
        setLoadingInformations,
        setLoadingServitudes,
        getLayerColor } = useCarto();
    const { getDocumentUrbanismeGeoJSON, getZoneUrbaineGeoJSON, getInfoUrbanismeGeoJSON, getSupGeoJSON } = useApi();
    const divContainer = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const [ layerKey, setLayerKey ] = useState(0); //Pour forcer le rechargement du layer cadastre (voir useEffect)

    /**
     * Constants
     */
    
    /**
     * useEffect
     */
    useEffect(() => {
        setLayerKey(layerKey + 1);
    }, [carto.showedFeatures, carto.selectedParcelle]);

    useEffect(() => {
        if(divContainer.current) {
            DomEvent.disableScrollPropagation(divContainer.current);
        }
    }, [divContainer, carto.selectedParcelle]);

    useEffect(() => {
        if(carto.selectedParcelle) {
            //On réinitialise les données
            setDocumentsUrbanisme(null);
            setNbDocumentsUrbanisme(0);
            setZonesUrbaine(null);
            setNbZonesUrbaine(0);
            setInformations(null);
            setNbInformations(0);
            setServitudes(null);
            setNbServitudes(0);
            setShowedFeatures(null);

            setCanViewParcelleData(true);
            
            //Load document urbanisme
            setLoadingDocumentsUrbanisme(true);
            getDocumentUrbanismeGeoJSON(carto.selectedParcelle.properties.code)
                .then((documentUrbanisme) => {
                    if((documentUrbanisme as { error: string, count: number, credits: number }).error) {
                        setCanViewParcelleData(false);
                        setNbDocumentsUrbanisme((documentUrbanisme as { error: string, count: number, credits: number }).count);
                        //setNbCreditsToViewCadastre((documentUrbanisme as { error: string, count: number, credits: number }).credits);
                    }
                    else {
                        setDocumentsUrbanisme(documentUrbanisme);
                        setNbDocumentsUrbanisme((documentUrbanisme as FeatureCollection).features.length);
                    }

                    setLoadingDocumentsUrbanisme(false);
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                    setLoadingDocumentsUrbanisme(false);
                });
                
            //Load zone urbaine
            setLoadingZonesUrbaine(true);
            getZoneUrbaineGeoJSON(null, carto.selectedParcelle.properties.code, null)
                .then((zoneUrbaine) => {
                    if((zoneUrbaine as { error: string, count: number, credits: number }).error) {
                        setCanViewParcelleData(false);
                        setNbZonesUrbaine((zoneUrbaine as { error: string, count: number, credits: number }).count);
                        //setNbCreditsToViewCadastre((zoneUrbaine as { error: string, count: number, credits: number }).credits);
                    }
                    else {
                        setZonesUrbaine(zoneUrbaine);
                        setNbZonesUrbaine((zoneUrbaine as FeatureCollection).features.length);
                    }

                    setLoadingZonesUrbaine(false);
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                    setLoadingZonesUrbaine(false);
                });
            
            //Load info urbanisme
            setLoadingInformations(true);
            getInfoUrbanismeGeoJSON(null, carto.selectedParcelle.properties.code, null)
                .then((infoUrbanisme) => {
                    if((infoUrbanisme as { error: string, count: number, credits: number }).error) {
                        setCanViewParcelleData(false);
                        setNbInformations((infoUrbanisme as { error: string, count: number, credits: number }).count);
                        //setNbCreditsToViewCadastre((infoUrbanisme as { error: string, count: number, credits: number }).credits);
                    }
                    else {
                        setInformations(infoUrbanisme);
                        setNbInformations((infoUrbanisme as FeatureCollection).features.length);
                    }

                    setLoadingInformations(false);
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                    setLoadingInformations(false);
                });
            
            //Load sup
            setLoadingServitudes(true);
            getSupGeoJSON(null, carto.selectedParcelle.properties.code, null, 'assiette')
                .then((sup) => {
                    if((sup as { error: string, count: number, credits: number }).error) {
                        setCanViewParcelleData(false);
                        setNbServitudes((sup as { error: string, count: number, credits: number }).count);
                        //setNbCreditsToViewCadastre((sup as { error: string, count: number, credits: number }).credits);
                    }
                    else {
                        setServitudes(sup);
                        setNbServitudes((sup as FeatureCollection).features.length);
                    }

                    setLoadingServitudes(false);
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                    setLoadingServitudes(false);
                });
        }
    }, [carto.selectedParcelle]);

    /**
     * Handlers
     */

    /**
     * Functions
     */
    const getLayerStyle = (feature) => {
        return {
            color: getLayerColor(feature),
            weight: 2,
            fillOpacity: 0.6
        };
    }

    /**
     * Render
     */
    if(!carto.selectedParcelle) return null;

    return (
        <div ref={divContainer}>
            <Pane name="selectedParcelles" style={{zIndex: 500}}>
                {carto.selectedParcelle && (
                    <GeoJSON key={layerKey+10000} data={carto.selectedParcelle} style={{
                        color: '#0000FF',
                        dashArray: '5, 5',
                        fillOpacity: 0
                    }} />
                )}
            </Pane>
            <Pane name="urbanisme" style={{zIndex: 300}}>
                <GeoJSON key={layerKey} data={carto.showedFeatures} style={getLayerStyle} pointToLayer={(feature, latLng) => L.circleMarker(latLng)}/>
            </Pane>
        </div>
    );
}
