import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDossier } from "../../../hooks/useDossier";
import { Dossier } from "../../../models/Dossier";
import { GeoJSON, Pane } from 'react-leaflet';

//Bootstrap
import { Card, InputGroup, Button, Row, Col, Badge, Accordion, Spinner } from "react-bootstrap";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload, faEye, faEyeSlash, faHouse, faLocationCrosshairs, faSquare, faSquareFull, faX } from "@fortawesome/free-solid-svg-icons";
import { faSquare as faSquareRegular } from "@fortawesome/free-regular-svg-icons";
import { useCarto } from "../../../hooks/useCarto";
import { useApi } from "../../../hooks/useApi";
import { Feature, FeatureCollection } from "geojson";
import { DomEvent, latLng, Layer } from "leaflet";
import L from "leaflet";
import { TransactionModal } from "./TransactionModal";
import { DocumentsUrbanisme } from "./DocumentsUrbanisme";

//Params

export const DocumentsUrbanismeCard = () => {
    /**
     * Hooks
     */
    const { carto, setSelectedCard } = useCarto();
    const divContainer = useRef<HTMLDivElement>(null);

    /**
     * States
     */

    /**
     * Constants
     */
    
    /**
     * useEffect
     */
    useEffect(() => {
        if(divContainer.current) {
            DomEvent.disableScrollPropagation(divContainer.current);
        }
    }, [divContainer, carto.selectedParcelle]);

    /**
     * Handlers
     */
    const handleClose = () => {
        setSelectedCard(null);
    }

    /**
     * Functions
     */

    /**
     * Render
     */
    if(!carto.selectedParcelle) return null;

    return (
        <div>
            <Card className="card-cadastre mt-2" style={{ minWidth: '500px', maxHeight: 'calc(100vh - 20px)', overflowY: 'auto' }}>
                <Card.Body>
                    <Row>
                        <Col className="d-flex mb-0 justify-content-between">
                            <span>Documents d'urbanisme de la parcelle</span>
                            <div>
                                <FontAwesomeIcon icon={faX} style={{ cursor: 'pointer' }} onClick={handleClose} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-1 h3">
                            {carto.selectedParcelle.properties.prefixe} / {carto.selectedParcelle.properties.section} / {carto.selectedParcelle.properties.numero} - {carto.selectedParcelle.properties.contenance} m²
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {!carto.documentsUrbanisme || carto.documentsUrbanisme?.features?.length == 0 ? (
                                <div className="text-muted">
                                    Aucun document trouvé pour cette parcelle
                                </div>
                            ) : (
                                <div className="p-3 bg-light border border-dark-subtle rounded" ref={divContainer} style={{ overflowY: 'auto' }}>
                                    <DocumentsUrbanisme documentsUrbanisme={carto.documentsUrbanisme} />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}
