import DbResponse from "../apps/dbResponse/DbResponse";
import DCarto from "../apps/dCarto/DCarto";
import DQuestions from "../apps/dQuestions/DQuestions";
import Error from "../apps/errors/Error";
import Home from "../apps/home/Home";

export const routes = [
    {
        path: "/",
        element: <Home />,
        errorElement: <Error />,
    },
    {
        path: "/reponse-apporteur/:dbToken",
        element: <DbResponse />,
    },
    {
        path: "/questions-apporteur/:dToken",
        element: <DQuestions />,
    },
    {
        path: "/carto/:idDossier/:uToken/:impersonateUser",
        element: <DCarto />,
    },
    {
        path: "/carto/:idDossier/:uToken",
        element: <DCarto />,
    },
    {
        path: "/prospection/:uToken",
        element: <DCarto />,
    },
    {
        path: "/prospection/:uToken/:impersonateUser",
        element: <DCarto />,
    },
];