//Bootstrap
import { Card, InputGroup, Button, Row, Col, Modal, Form } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

//Hooks
import { useDataGouv } from "../hooks/useDataGouv";

//React
import React from 'react';
import { useEffect, useState } from "react";

//Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useMapInfo } from "../hooks/useMapInfo";
import { set } from "react-hook-form";
import logo from '../assets/logo.png';
import { api } from "../helpers/api";
import { useApi } from "../hooks/useApi";
import axios from "axios";

interface ModalLoginParams {
    urlPageToReload?: string;
};

function ModalLogin({ urlPageToReload }: ModalLoginParams) {
    /**
     * Hooks
     */
    const { login } = useApi();
    
    /**
     * States
     */
    const [show, setShow] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    /**
     * useEffect
     */
    useEffect(() => {
        //Ajout d'un interceptor pour gérer les erreurs 401
        api.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(error.response.status === 401) {
                setShow(true);
            }

            return Promise.reject(error);
        });
    }, []);
    
    /**
     * Handlers
     */
    const handleLogin = () => {
        //On envoie la requete de connexion
        login(username, password)
            .then((response) => {
                let token = response.token;
                
                //On enregistre le token de l'utilisateur dans axios
                api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                if(urlPageToReload) window.location.href = urlPageToReload + '/' + token;

                setShow(false);
            })
            .catch((error) => {
                console.log(error);
            }
        );
    }
    
    /**
     * Functions
     */
        
    /**
     * Render
     */
    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Reconnexion nécessaire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Il semble que vous ayez été déconnecté, merci d'entrer à nouveau vos identifiants</p>
                    <Form.Label className="mt-3">Nom d'utilisateur</Form.Label>
                    <Form.Control type="text" placeholder="Identifiant" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <Form.Label className="mt-3">Mot de passe</Form.Label>
                    <Form.Control type="password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleLogin}>Connexion</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalLogin;