import { useParams } from "react-router-dom";
import Map from "../../sharedComponents/Map";
import { useEffect, useState } from "react";
import { useMapInfo } from "../../hooks/useMapInfo";
import { useMapUtil } from "../../hooks/useMapUtil";
import { api } from "../../helpers/api";
import { useDossier } from "../../hooks/useDossier";
import { Dossier } from "../../models/Dossier";
import { DossierCard } from "./components/DossierCard";
import MapCadastreLayer from "../../sharedComponents/MapCadastreLayer";
import MapMutationLayer from "./components/MapMutationLayer";
import GeoDataLayers from "./components/GeoDataLayers";
import { CadastreInfosLayer } from "./components/CadastreInfosLayer";
import { useCarto } from "../../hooks/useCarto";
import { useDataGouv } from "../../hooks/useDataGouv";
import { FeatureCollection } from "geojson";
import ModalLogin from "../../sharedComponents/ModalLogin";
import { useMap } from "react-leaflet";
import { useApi } from "../../hooks/useApi";
import { get } from "http";
import { useUser } from "../../hooks/useUser";
import Sidebar from "./components/Sidebar";
import { MutationCard } from "./components/MutationCard";
import { DocumentsUrbanismeCard } from "./components/DocumentsUrbanismeCard";
import { ZonesUrbaineCard } from "./components/ZonesUrbaineCard";
import { InformationsCard } from "./components/InformationsCard";
import { ServitudesCard } from "./components/ServitudesCard";
import { calculateCentroid } from "../../helpers/GeoHelper";
import CardAddress from "../../sharedComponents/CardAddress";


function DCarto() {
    const NB_KM_MAX = 1000;
    
    /**
     * Hooks
     */
    const routeParams = useParams();
    const { idDossier, uToken, impersonateUser } = routeParams;
    const { mapInfo, centerMap, setStoreSelectedCadastres, setMaxBounds } = useMapInfo();
    const { createLoader, deleteLoader } = useMapUtil();
    const { getDossier } = useDossier();
    const { carto, setSelectedParcelle, setSelectedCard } = useCarto();
    const { apiAddress } = useDataGouv();
    const { getCadastresGeoJSON, getCadastreGeoJSON } = useApi();
    const { getProfile } = useUser();

    /**
     * States
     */
    const [ dossier, setDossier ] = useState<Dossier>(null);
    const [ layers, setLayers ] = useState([]);
    const [ isLibre, setIsLibre ] = useState(false);
    
    /**
     * useEffect
     */
    useEffect(() => {
        if(uToken) {
            //On enregistre le token de l'utilisateur dans axios
            api.defaults.headers.common['Authorization'] = `Bearer ${uToken}`;
            if(impersonateUser) api.defaults.headers.common['x-switch-user'] = impersonateUser;
            
            //Chargement du profil de l'utilisateur
            getProfile();

            //On charge les informations du dossier
            if(idDossier) {
                createLoader('dossier', 'Chargement des informations du dossier');
                getDossier(idDossier)
                    .then((dossier) => {
                        setDossier(dossier);
                        
                        deleteLoader('dossier');
                    })
                    .catch((error) => {
                        console.error(error); //TODO: Gérer l'erreur
                        deleteLoader('dossier');
                    });
            }
        }
    }, [uToken]);

    useEffect(() => {
        if(dossier) {
            setIsLibre(false);
            createLoader('localisationDossier', 'Localisation du dossier');
            //On commence par récupérer le code insee de la commune
            apiAddress(dossier.codePostal+' '+dossier.commune, 1)
                .then((data) => {
                    if(data.features?.length > 0) {
                        //On reconstitue le code de la première parcelle du dossier
                        let parcelle = dossier.parcelles[0];
                        let prefixe = String(parcelle.prefixe).padStart(3, '0');
                        let section = String(parcelle.section).padStart(2, '0');
                        let numero = String(parcelle.numero).padStart(4, '0');
                        let code = data.features[0].properties.citycode + prefixe + section + numero;
                        
                        //On récupère les informations de la parcelle dans le cadastre
                        getCadastreGeoJSON(code)
                            .then((data) => {
                                //On récupère le point central du geojson
                                let polygon = null;
                                if(data.geometry.type == 'GeometryCollection') polygon = (data as any).geometry.geometries[0];
                                else polygon = (data as any).geometry;
                                
                                //Récupération du centroid de la parcelle
                                let centroid = calculateCentroid(polygon);
                                
                                let longitude = centroid.geometry.coordinates[0];
                                let latitude = centroid.geometry.coordinates[1];
                                
                                //On centre la carte sur la parcelle
                                setMaxBounds({
                                    longitude: longitude,
                                    latitude: latitude
                                }, NB_KM_MAX);

                                centerMap({
                                    longitude: longitude,
                                    latitude: latitude
                                }, 19);

                                deleteLoader('localisationDossier');
                    })
                            .catch((error) => {
                                localiserSurDossier();
                                deleteLoader('localisationDossier');
                            });
                    }
                    else {

                    }
                })
                .catch((error) => {
                    localiserSurDossier();
                    deleteLoader('localisationDossier');
                });
        }
        else {
            setIsLibre(true);
        }
    }, [dossier]);

    useEffect(() => {
        if(dossier && mapInfo.cadastres && mapInfo.cadastres.features?.length > 0) {
            //Ajout des parcelles du dossier sur la carte
            let features = [];
            dossier.parcelles.forEach((parcelle) => {
                //On récupère les informations de la parcelle dans le cadastre
                let feature = mapInfo.cadastres.features.filter((cadastre) => {
                    return (cadastre.properties.prefixe == parcelle.prefixe || cadastre.properties.prefixe == String(parcelle.prefixe).padStart(3, '0')) && cadastre.properties.section == parcelle.section && cadastre.properties.numero == parcelle.numero;
                });

                //On ajoute la parcelle à la carte
                if(feature.length > 0) features = [...features, feature[0]];
            });

            setStoreSelectedCadastres({
                type: "FeatureCollection",
                features: features
            });
        }
    }, [dossier, mapInfo.cadastres]);

    /**
     * Handlers
     */
    const handleClickOnCadastre = (feature) => {
        setSelectedParcelle(feature);
    }

    const handleSelectItem = (item) => {
        setSelectedCard(item);
    }

    /**
     * Functions
     */
    const localiserSurDossier = () => {
        if(dossier.latitude && dossier.longitude) {
            //On limite la carte à 1km de rayon
            setMaxBounds({
                longitude: dossier.longitude,
                latitude: dossier.latitude
            }, NB_KM_MAX);

            //On centre la carte sur le dossier
            centerMap({
                longitude: dossier.longitude,
                latitude: dossier.latitude
            }, 19);
        }
        else {
            //On centre la carte sur l'adresse du dossier
            apiAddress((dossier.adresse?dossier.adresse!='INCONNUE'?dossier.adresse:'':'')+' '+dossier.codePostal+' '+dossier.commune, 1)
                .then((data) => {
                    if(data.features?.length > 0) {
                        let geometry = data.features[0].geometry as any;
                        //On limite la carte à 1km de rayon
                        setMaxBounds({
                            longitude: geometry.coordinates[0],
                            latitude: geometry.coordinates[1]
                        }, NB_KM_MAX);

                        centerMap({
                            longitude: geometry.coordinates[0],
                            latitude: geometry.coordinates[1]
                        }, 19);
                    }
                    else {
                        //TODO: on affiche une erreur informant que l'adresse n'a pas été trouvée
                    }
                })
                .catch((error) => {
                    console.error(error); //TODO: Gérer l'erreur
                });
        }
    }

    /**
     * Render
     */
    return (
        <div className="d-flex">
            <Sidebar onSelectItem={handleSelectItem} isLibre={isLibre} />
            <div>
                <Map decalageGauche={80}>
                    <div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 10000 }}>
                        {isLibre && (
                            <CardAddress showSearchByRef={false} />
                        )}
                        {carto.selectedCard == 'dossier' && (
                            <DossierCard dossier={dossier} />
                        )}
                        {carto.selectedCard == 'mutations' && (
                            <MutationCard />
                        )}
                        {carto.selectedCard == 'documentsUrbanisme' && (
                            <DocumentsUrbanismeCard />
                        )}
                        {carto.selectedCard == 'zonesUrbaine' && (
                            <ZonesUrbaineCard />
                        )}
                        {carto.selectedCard == 'informations' && (
                            <InformationsCard />
                        )}
                        {carto.selectedCard == 'servitudes' && (
                            <ServitudesCard />
                        )}
                        </div>
                    <MapCadastreLayer onCadastreClick={handleClickOnCadastre} />
                    <GeoDataLayers />
                    <CadastreInfosLayer />
                </Map>
            </div>
            
            <ModalLogin urlPageToReload={'/carto/'+idDossier} />
        </div>
    );
}

export default DCarto;
