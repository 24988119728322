import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDossier } from "../../../hooks/useDossier";
import { Dossier } from "../../../models/Dossier";
import { GeoJSON, Pane } from 'react-leaflet';

//Bootstrap
import { Card, InputGroup, Button, Row, Col, Badge, Accordion, Spinner } from "react-bootstrap";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCalendar, faCaretRight, faCaretDown, faEye, faEyeSlash, faHouse, faLocationCrosshairs, faSquare, faSquareFull, faArrowUpRightFromSquare, faClock } from "@fortawesome/free-solid-svg-icons";
import { useCarto } from "../../../hooks/useCarto";
import { useApi } from "../../../hooks/useApi";
import { Feature, FeatureCollection } from "geojson";
import { latLng, Layer } from "leaflet";
import L from "leaflet";
import { TransactionModal } from "./TransactionModal";

//Params
interface DocumentsUrbanismeParams {
    documentsUrbanisme: FeatureCollection;
}

export const DocumentsUrbanisme = ({ documentsUrbanisme }: DocumentsUrbanismeParams) => {
    /**
     * Hooks
     */
    

    /**
     * States
     */
    const [openedDocument, setOpenedDocument] = useState<Feature | null>(null);
    

    /**
     * Constants
     */
    
    
    /**
     * useEffect
     */
    

    /**
     * Handlers
     */
    

    /**
     * Functions
     */
    

    /**
     * Render
     */
    if(!documentsUrbanisme) return null;

    return (
        <>
            {documentsUrbanisme && documentsUrbanisme.features.map((document) => (
                <div key={document.properties.id}>
                    <FontAwesomeIcon icon={document.properties.id == openedDocument?.properties?.id?faCaretDown:faCaretRight} /> <a style={{ cursor: 'pointer' }} onClick={() => setOpenedDocument(document.properties.id == openedDocument?.properties?.id?null:document)}><strong>{document.properties.title}</strong></a>
                    <div className="d-flex flex-row-reverse">
                        <div className="text-muted">
                            <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px' }} />
                            {new Date(document.properties.updateDate).toLocaleDateString()}
                        </div>
                    </div>
                    {document.properties.id == openedDocument?.properties?.id && (
                        <div style={{ marginLeft: '10px' }}>
                            {document.properties.files.map((file) => file.path).filter((item, pos) => document.properties.files.map((file) => file.path).indexOf(item) == pos).map((path) => (
                                <div key={'path-'+path} className="mt-2">
                                    <u><>{path}</></u>
                                    <div style={{ marginLeft: '10px' }}>
                                        {document.properties.files.filter((file) => file.path == path).map((file) => (
                                            <p key={file.id} className="my-0">
                                                <a href={file.urlSource} target="_blank">{file.title}</a> <a href={file.urlSource} target="_blank"><FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ cursor: 'pointer', marginLeft: '5px' }} /></a>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </>
    );
}
