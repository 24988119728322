import { AxiosError, AxiosResponse } from "axios";
import { api } from "../helpers/api";
import { Brouillon } from "../models/Brouillon";
import { User } from "../models/User";
import { get } from "http";
import { Feature } from "geojson";

export const useTransaction = () => {
    const commanderCadastre = (codeCadastre: string) => {
        return new Promise<Feature>((resolve, reject) => {
            api.get('/transaction/cadastre/' + codeCadastre)
                .then((response: AxiosResponse<Feature>) => resolve(response?.data))
                .catch((error: AxiosError) => reject(error?.response?.data));
        });
    }

    return {
        commanderCadastre
    }
}
