//Redux
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedCard: null,
    mutations: null,
    dossierParcelles: [],
    selectedParcelle: null,
    canViewParcelleData: false,
    documentsUrbanisme: null,
    zonesUrbaine: null,
    informations: null,
    servitudes: null,
    showedFeatures: null,
    nbDocumentsUrbanisme: 0,
    nbZonesUrbaine: 0,
    nbInformations: 0,
    nbServitudes: 0,
    loadingDocumentsUrbanisme: false,
    loadingZonesUrbaine: false,
    loadingInformations: false,
    loadingServitudes: false
};

/**
 * The Carto slice
 */
export const cartoSlice = createSlice({
	name: 'carto',
	initialState,
	reducers: {
        setCarto: (state, action) => {
            return state = action.payload;
        },
        setSelectedCard: (state, action) => {
            state.selectedCard = action.payload;
        },
        addMutation: (state, action) => {
            if(!state.mutations) state.mutations = [];
            state.mutations.push(action.payload);
        },
        setMutations: (state, action) => {
            state.mutations = action.payload;
        },
        addDossierParcelle: (state, action) => {
            state.dossierParcelles.push(action.payload);
        },
        setDossierParcelles: (state, action) => {
            state.dossierParcelles = action.payload;
        },
        setSelectedParcelle: (state, action) => {
            state.selectedParcelle = action.payload;
        },
        setCanViewParcelleData: (state, action) => {
            state.canViewParcelleData = action.payload;
        },
        setDocumentsUrbanisme: (state, action) => {
            state.documentsUrbanisme = action.payload;
        },
        addDocumentUrbanisme: (state, action) => {
            if(!state.documentsUrbanisme) state.documentsUrbanisme = [];
            state.documentsUrbanisme.push(action.payload);
        },
        setZonesUrbaine: (state, action) => {
            state.zonesUrbaine = action.payload;
        },
        addZoneUrbaine: (state, action) => {
            if(!state.zonesUrbaine) state.zonesUrbaine = [];
            state.zonesUrbaine.push(action.payload);
        },
        setInformations: (state, action) => {
            state.informations = action.payload;
        },
        addInformation: (state, action) => {
            if(!state.informations) state.informations = [];
            state.informations.push(action.payload);
        },
        setServitudes: (state, action) => {
            state.servitudes = action.payload;
        },
        addServitude: (state, action) => {
            if(!state.servitudes) state.servitudes = [];
            state.servitudes.push(action.payload);
        },
        setShowedFeatures: (state, action) => {
            state.showedFeatures = action.payload;
        },
        addShowedFeature: (state, action) => {
            if(!state.showedFeatures) state.showedFeatures = [];
            state.showedFeatures.push(action.payload);
        },
        setNbDocumentsUrbanisme: (state, action) => {
            state.nbDocumentsUrbanisme = action.payload;
        },
        setNbZonesUrbaine: (state, action) => {
            state.nbZonesUrbaine = action.payload;
        },
        setNbInformations: (state, action) => {
            state.nbInformations = action.payload;
        },
        setNbServitudes: (state, action) => {
            state.nbServitudes = action.payload;
        },
        setLoadingDocumentsUrbanisme: (state, action) => {
            state.loadingDocumentsUrbanisme = action.payload;
        },
        setLoadingZonesUrbaine: (state, action) => {
            state.loadingZonesUrbaine = action.payload;
        },
        setLoadingInformations: (state, action) => {
            state.loadingInformations = action.payload;
        },
        setLoadingServitudes: (state, action) => {
            state.loadingServitudes = action.payload;
        },
	}
});

export const { 
    setCarto,
    setSelectedCard,
    addMutation,
    setMutations,
    addDossierParcelle,
    setDossierParcelles,
    setSelectedParcelle,
    setCanViewParcelleData,
    setDocumentsUrbanisme,
    addDocumentUrbanisme,
    setZonesUrbaine,
    addZoneUrbaine,
    setInformations,
    addInformation,
    setServitudes,
    addServitude,
    setShowedFeatures,
    addShowedFeature,
    setNbDocumentsUrbanisme,
    setNbZonesUrbaine,
    setNbInformations,
    setNbServitudes,
    setLoadingDocumentsUrbanisme,
    setLoadingZonesUrbaine,
    setLoadingInformations,
    setLoadingServitudes
} = cartoSlice.actions;
export const selectCarto = (state: any) => state.carto;

export default cartoSlice.reducer;
