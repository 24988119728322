//Redux
import { createSlice } from "@reduxjs/toolkit";
import { User } from "../models/User";

const initialState:User = {
	email: '',
    firstname: '',
    lastname: '',
};

/**
 * The Profile slice
 */
export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
        setProfile: (state, action) => {
            return state = action.payload;
        },
	}
});

export const { 
    setProfile
} = profileSlice.actions;
export const selectProfile = (state: any) => state.profile;

export default profileSlice.reducer;
